@tailwind base;

@tailwind components;

@tailwind utilities;

body {
  margin: 0;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

/* fix issues with other overlay-sticky elements */
#cwf-modal-root {
  position: absolute;
  z-index: 9999;
}

/* a very specific hack for a bug with SimpleModal that is preventing dropdowns from displaying within the container*/
.gr-inline-block.gr-pb-4.gr-overflow-hidden.gr-text-left.gr-align-bottom.gr-transition-all.gr-transform.gr-bg-white.gr-rounded.gr-shadow-xl.sm\:gr-my-8.sm\:gr-align-middle.sm\:gr-max-w-lg.sm\:gr-w-full.false {
  overflow: initial;
}

/* Fixes issues that allow GrowUI Tab components to expand past their parent's height */
div[data-testid='gr-card'],
fieldset {
  height: 100%;
}

div[data-testid='tabs-default'] .gr--mt-px.false {
  height: calc(100% - 38px);
}

.gr--mt-px {
  padding-bottom: 0;
}

div[data-testid='tabs-default'] {
  height: 100%;
}

div[data-testid='tabs-default'] .gr-block {
  height: 100%;
}

div[data-testid='tabs-default'] .gr-block > div {
  background-color: white;
}

.ant-col,
.ant-row {
  font-family: 'Noto Sans', sans-serif;
}

.syt-antd-input-suffix {
  height: 100%;
  & .syt-antd-input-clear-icon {
    height: 100%;
  }
}

/* to work properly with icons */
.syt-antd-btn {
  display: inline-flex;
  align-items: center;
  gap: 4px;
}

/* fix/remove blue border on hover when button is disabled */
.syt-antd-btn[disabled],
.syt-antd-btn.syt-antd-btn-background-ghost[disabled],
.syt-antd-btn[disabled]:hover {
  border-color: #c2c7d0;
}

/* to make icons automatically have the same color as text on disabled mode */
.syt-antd-btn[disabled] > svg path {
  fill: currentColor;
}

.syt-antd-btn-background-ghost.syt-antd-btn-primary {
  color: #14803c;
  border-color: #14803c;

  &:hover,
  &:focus {
    color: #19a04b;
    border-color: #19a04b;
  }

  &:focus {
    box-shadow: 0px 0px 3px rgba(25, 160, 75, 0.25);
  }

  &:active {
    color: #0c612c;
    border-color: #0c612c;
  }
}

.syt-antd-checkbox-checked .syt-antd-checkbox-inner {
  border-color: #0071cd !important;
  background-color: #0071cd !important;
}

.syt-antd-checkbox-indeterminate .syt-antd-checkbox-inner {
  border-color: #0071cd !important;

  &::after {
    background-color: #0071cd !important;
  }
}

.syt-antd-checkbox-inner::after {
  top: 49%;
  left: 21%;
}

.syt-antd-popover-content {
  width: auto;
}

.syt-antd-popover-inner-content,
.syt-antd-popover-content .syt-antd-popover-inner-content {
  padding: 0;
}
.syt-antd-popover-placement-bottom,
.syt-antd-popover-placement-bottomLeft,
.syt-antd-popover-placement-bottomRight {
  padding-top: 0;
}
.anticon.anticon-check.syt-antd-steps-finish-icon {
  display: block;
  margin-top: 6px;
}

.syt-antd-steps-item.syt-antd-steps-item-process.syt-antd-steps-item-active
  .syt-antd-steps-item-title {
  font-weight: 600;
}

.syt-antd-radio-wrapper {
  width: 100%;
}

.syt-antd-radio-inner::after {
  top: 7px;
  left: 7px;
}

span:not(.syt-antd-radio-checked) .syt-antd-radio-inner {
  border-color: #c2c7d0;
}
