.date-filtering-connect .syt-antd-picker-ranges {
  padding: 10px 12px;
}

.date-filtering-connect .syt-antd-picker-ranges .syt-antd-picker-preset > .syt-antd-tag-blue {
  cursor: pointer;
  height: 24px;
  padding: 4px 12px;
  color: #333333;
  font-weight: 300;
  background: #FFFFFF;
  border-color: #C2C7D0;
}

.date-filtering-connect .syt-antd-picker-ranges .syt-antd-picker-preset .syt-antd-tag-blue:hover {
  background: #F3F4F6;
}
