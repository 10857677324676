.filter-container {
  .syt-antd-tree-switcher {
    line-height: 18px;
    width: 32px;
  }

  span:not(.syt-antd-tree-checkbox-checked) .syt-antd-tree-checkbox-inner {
    border: 1px solid #c2c7d0;
    border-radius: 2px;
  }

  .syt-antd-tree-checkbox.syt-antd-tree-checkbox-checked
    .syt-antd-tree-checkbox-inner {
    border-radius: 2px;
  }
}
